import classNames from 'classnames';
import { h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useCallback, useState } from 'preact/hooks';

import { noop } from '~/utils/noop';

import * as styles from './styles.css';

const renderDefault = (props) => <input {...props} />;

export const InputBase = forwardRef((props, ref) => {
  const [isFocusedFromState, setFocus] = useState(false);

  const {
    disabled = false,
    isFocused = isFocusedFromState,
    isInvalid = false,
    readOnly = false,
    withDeco = true,

    render = renderDefault,
    onBlur = noop,
    onFocus = noop,
    ...rest
  } = props;

  const handleBlur = useCallback(
    (event) => {
      setFocus(false);
      onBlur(event);
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (event) => {
      setFocus(true);
      onFocus(event);
    },
    [onFocus],
  );

  return (
    <span
      className={classNames(
        styles.container,
        disabled ? styles.isDisabled : null,
        isFocused ? styles.isFocused : null,
        isInvalid ? styles.isInvalid : null,
      )}
    >
      {render({
        ...rest,
        'aria-disabled': disabled,
        'aria-invalid': isInvalid,
        'aria-readonly': readOnly,
        className: styles.field,
        disabled,
        readOnly,
        ref,

        onBlur: handleBlur,
        onFocus: handleFocus,
      })}

      {withDeco ? <span className={styles.background} /> : null}
    </span>
  );
});
