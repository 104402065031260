import { useEffect, useRef } from 'preact/hooks';

export default function useEventListener(eventName, handler, options = {}) {
  const { element = window, once = false } = options;
  const handlerRef = useRef();

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return;
    }

    const eventListener = (event) => {
      handlerRef.current(event);

      if (once) {
        element.removeEventListener(eventName, eventListener);
      }
    };

    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, element]);
}
