import { h } from 'preact';

import * as styles from './styles.css';

const ShadowField = ({ id, onFocus }) => (
  <input
    aria-hidden={true}
    className={styles.shadowField}
    id={id}
    type="text"
    onFocus={onFocus}
  />
);

export default ShadowField;
