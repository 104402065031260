import { useMemo } from 'preact/hooks';

import { decode, encode } from '~/utils/urlParams';

const PARAMS_MAPPING = [
  { long: 'autoFocus', short: 'af' },
  { long: 'placeholder', short: 'ph' },
  {
    long: 'styles',
    short: 's',
    children: [
      {
        long: 'cardTokenizer',
        short: 'cn',
        children: [
          { long: 'inputContainer', short: 'ic', children: 'all' },
          { long: 'inputField', short: 'if', children: 'all' },
          { long: 'inputFieldIsFocused', short: 'iff', children: 'all' },
          { long: 'inputFieldIsInvalid', short: 'ifi', children: 'all' },
          { long: 'inputFieldPlaceholder', short: 'ifp', children: 'all' },
        ],
      },
      {
        long: 'bankAccountTokenizer',
        short: 'bat',
        children: [
          { long: 'inputContainer', short: 'ic', children: 'all' },
          { long: 'inputField', short: 'if', children: 'all' },
          { long: 'inputFieldIsFocused', short: 'iff', children: 'all' },
          { long: 'inputFieldIsInvalid', short: 'ifi', children: 'all' },
          { long: 'inputFieldPlaceholder', short: 'ifp', children: 'all' },
        ],
      },
    ],
  },
  { long: 'publicToken', short: 'pt' },
  { long: 'type', short: 't' },
];

export function useEncodedUrl(path, params) {
  return useMemo(() => {
    const baseURL = `${process.env.APP_BASE_URL}${path}`;
    const query = encode(params, PARAMS_MAPPING);

    if (query) {
      return `${baseURL}?${query}`;
    }

    return baseURL;
  }, [path, params]);
}

export function useDecodedUrl(url) {
  return useMemo(() => {
    const [, queryString = ''] = url.split('?');

    return decode(queryString, PARAMS_MAPPING);
  }, [url]);
}
