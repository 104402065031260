/**
 * @callback Checker
 * @param {*} value - Значение параметра.
 * @param {string} name - Имя параметра.
 * @returns {(undefined|string)} - Возвращает:
 *                                   - undefined, если валидация прошла успешно, или
 *                                   - текст ошибки.
 */

/**
 * Проверяет значения на соответствие правилам.
 *
 * @param {Object.<string, *>} values - Значения для проверки.
 * @param {Array.<string, Checker>} rules - Значения для проверки.
 * @returns {Object.<string, string>} - Возвращает:
 *                                        - пустой объект, в случае успешно прошедшей валидации, или
 *                                        - объект, в котором ключ соответствует имени поля,
 *                                                а значение - тексту ошибки.
 */
export const validate = (values, rules) => {
  return Object.entries(rules).reduce((result, [name, rule]) => {
    const { [name]: value } = values;
    let error;

    if (Array.isArray(rule)) {
      error = rule.reduce((resultError, itemRule) => {
        if (resultError === undefined) {
          return itemRule(value, name, values);
        }

        return resultError;
      }, undefined);
    } else {
      error = rule(value, name);
    }

    if (error === undefined) {
      return result;
    }

    return {
      ...result,
      [name]: error,
    };
  }, {});
};
