import { h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useCallback, useEffect, useState } from 'preact/hooks';

import usePreviousValue from '~/hooks/usePreviousValue';

const ProviderForm = forwardRef((props, ref) => {
  const {
    autoFocus,
    inputRef,
    placeholder,
    validationRules,

    normalizeValue,
    onBlur,
    onChange,
    onFocus,
    onInvalid,
    onSubmit,
    onValid,
    render,

    ...rest
  } = props;

  const [value, setValue] = useState('');
  const normalizedValue = normalizeValue(value);

  const [error, setError] = useState();
  const [isTouched, touch] = useState(false);

  useEffect(() => {
    const nextError = validationRules.reduce((result, validate) => {
      if (result) {
        return result;
      }

      return validate(normalizedValue);
    }, undefined);

    if (nextError !== error) {
      setError(nextError);
    }
  }, [normalizedValue, error, validationRules]);

  const prevIsTouched = usePreviousValue(isTouched);
  const prevError = usePreviousValue(error);

  useEffect(() => {
    if (!isTouched) {
      return;
    }

    if (isTouched === prevIsTouched && error === prevError) {
      return;
    }

    if (error) {
      onInvalid(error);
    } else {
      onValid();
    }
  }, [error, isTouched, prevIsTouched, prevError, onInvalid, onValid]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!isTouched) {
        touch(true);
      }

      if (error) {
        onInvalid(error);
      } else {
        onSubmit(value);
      }
    },
    [isTouched, error, onInvalid, onSubmit, value],
  );

  const handleBlur = useCallback(() => {
    touch(true);
    onBlur(error);
  }, [error, onBlur]);

  const handleChange = useCallback(
    (event) => {
      const { target: { value: currentValue = event } = {} } = event;

      setValue(currentValue);
      onChange();
    },
    [onChange],
  );

  const handleFocus = useCallback(() => onFocus(), [onFocus]);

  return (
    <form ref={ref} onSubmit={handleSubmit}>
      {render({
        ...rest,
        autoFocus,
        isInvalid: isTouched && error !== undefined,
        placeholder,
        ref: inputRef,
        value,

        onBlur: handleBlur,
        onChange: handleChange,
        onFocus: handleFocus,
      })}
    </form>
  );
});

ProviderForm.defaultProps = {
  autoFocus: false,
  validationRules: [],

  onBlur: () => null,
  onChange: () => null,
  onFocus: () => null,
};
ProviderForm.displayName = 'ProviderForm';

export default ProviderForm;
