import { useCallback, useEffect, useRef } from 'preact/hooks';

import useEventListener from '~/hooks/useEventListener';
import { post } from '~/utils/report';

export default function useCreateCommunicator(channel, getTargets) {
  const handlers = useRef({});
  const handleReceiveMessage = useCallback(
    ({ data }) => {
      const { meta: { channel: id, eventName } = {}, payload } = data;

      if (id === channel && typeof handlers.current[eventName] === 'function') {
        handlers.current[eventName](payload);
      }
    },
    [channel],
  );

  useEventListener('message', handleReceiveMessage);

  return {
    useReceiver(eventName, handler) {
      useEffect(() => {
        handlers.current = {
          ...handlers.current,
          [eventName]: handler,
        };
      }, [eventName, handler]);
    },

    useSender(eventName) {
      return useCallback(
        (payload) =>
          post(
            {
              meta: { channel, eventName },
              payload,
            },
            { targets: getTargets() },
          ),
        [eventName],
      );
    },
  };
}
