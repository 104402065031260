import { transactionStatuses } from '~/constants/transactionStatuses';
import { asyncDelay } from '~/utils/asyncDelay';
import WidgetError from '~/utils/errors';
import fetch from '~/utils/fetch';

/**
 * @typedef PublicToken
 *
 * @type {string}
 */

const api = {
  payment: {
    /**
     * Инициализация выплаты.
     *
     * @param {Object} params — Параметры.
     * @param {number} params.publicToken - Публичный токен.
     * @param {string} params.token - Токенизированные данные карты.
     * @param {string} [params.fullName] - Имя получателя.
     * @param {Object} [params.sender] - Объект с данными отправителя.
     * @param {string} [params.sender.address_line] - Адрес.
     * @param {string} [params.sender.city] - Город.
     * @param {string} [params.sender.company_name] - Название компании.
     * @param {string} [params.sender.country_iso2] - Страна (ISO-3166-1 alpha-2).
     * @param {string} [params.sender.country_iso3] - Страна (ISO-3166-1 alpha-3).
     * @param {string} [params.sender.full_name] - Имя.
     * @param {string} [params.sender.ipv4] - Ip адрес версии 4.
     * @param {string} [params.sender.ipv6] - Ip адрес версии 6.
     * @param {string} [params.sender.postal_code] - Почтовый адрес.
     * @param {string} [params.sender.reference] - Идентификатор компнии.
     * @returns {Promise} — Данные сессии.
     */
    payout({ fullName, publicToken, sender, token }) {
      const recipientPayload = fullName
        ? {
            recipient: { full_name: fullName },
          }
        : {};
      const senderPayload = sender ? { sender } : {};

      return fetch('/checkout/v1/init/payout', {
        body: JSON.stringify({
          ...(fullName || sender
            ? {
                participant_details: {
                  ...recipientPayload,
                  ...senderPayload,
                },
              }
            : {}),
          payment_method: {
            type: 'card',
            card: {
              type: 'encrypted_card',
              encrypted_card: {
                number_hash: token,
              },
            },
          },
        }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        method: 'post',
      });
    },

    /**
     * Инициализация платежа.
     *
     * @param {Object} params — Параметры.
     * @param {unknown} params.meta - Мета-данные.
     * @param {string} params.publicToken - Публичный токен.
     * @param {string} params.tokenCardholder - Токен номера карты.
     * @param {string} params.tokenCardNumber - Токен номера карты.
     * @param {string} params.tokenCvv - Токен CVV/CVC кода.
     * @param {string} params.tokenExpiryDate - Токен срока действия карты.
     * @returns {Promise} — Статус операции.
     */
    pay(params) {
      const {
        meta,
        publicToken,
        recurrent,
        tokenCardholder,
        tokenCardNumber,
        tokenCvv,
        tokenExpiryDate,
      } = params;

      return fetch('/widget/v1/payment', {
        body: JSON.stringify({
          ...(meta ? { payment_metadata: meta } : {}),
          payment_details: {
            type: 'card',
            card: {
              type: 'encrypted_card',
              encrypted_card: {
                number_hash: tokenCardNumber,
                expiration_date_hash: tokenExpiryDate,
                security_code_hash: tokenCvv,
                cardholder_name_hash: tokenCardholder,
              },
            },
          },
          payment_options: {
            recurrent,
          },
        }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        isStrict: true,
        method: 'post',
      });
    },

    /**
     * @typedef Transaction
     * @member {string} id
     * @member {transactionStatuses} status
     * @member {string} created_at — For instance, "2020-04-01T09:51:49.658947Z"
     * @member {Object} customer
     * @member {string} customer.reference
     * @member {Object} payment_details
     * @member {'card'} payment_details.type
     * @member {Object} payment_details.card
     * @member {string} payment_details.card.brand
     * @member {string} payment_details.card.last4
     * @member {Object} amount_details
     * @member {number} amount_details.amount
     * @member {string} amount_details.currency
     * @member {Object} customer_interaction
     * @member {'redirect'} customer_interaction.type
     * @member {Object} customer_interaction.redirect
     * @member {string} customer_interaction.redirect.url
     * @member {'GET'|'POST'} customer_interaction.redirect.method
     * @member {Object.<{ key: string, value: string }>} customer_interaction.redirect.qs
     */

    async getStatus(params = {}, options = {}) {
      const { headers, url } = params;
      const { skipCustomerInteraction = false, successOnHold = false } =
        options;
      const {
        payment_session: {
          acquiring_payments: [transaction = {}] = [],
          next_action: nextAction,
        },
      } = await fetch(url, {
        headers,
        isStrict: true,
        method: 'get',
      });
      const { customer_interaction: customerInteraction, status } = transaction;

      if (
        status === transactionStatuses.pending &&
        successOnHold &&
        nextAction === 'capture'
      ) {
        // Отображение успеха при холдировании средств.
        return transaction;
      }

      if (
        status === transactionStatuses.pending &&
        customerInteraction &&
        !skipCustomerInteraction
      ) {
        // Отработка взаимодействия с плательщиком.
        return transaction;
      }

      if (
        [transactionStatuses.inProgress, transactionStatuses.pending].includes(
          status,
        )
      ) {
        await asyncDelay(3000);

        return await api.payment.getStatus(params, options);
      }

      return transaction;
    },

    async getStatusByToken(publicToken, options) {
      return await api.payment.getStatus(
        {
          headers: { 'X-PUBLIC-TOKEN': publicToken },
          url: '/widget/v1/session/status',
        },
        options,
      );
    },

    async getStatusByHash({ hashId, sign }, options) {
      return await api.payment.getStatus(
        {
          url: `/transaction/internal/v1/session/status/${hashId}/${sign}`,
        },
        {
          ...options,
          skipCustomerInteraction: true,
        },
      );
    },
  },

  token: {
    async create({ name: ref, publicToken, type, value }) {
      const { data } = await fetch('/cds/v1/tokenize/elements', {
        body: JSON.stringify({
          card_elements: [{ ref, type, [ref]: value }],
        }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        isStrict: true,
        method: 'post',
      });

      return data;
    },

    async createBankAccountToken({ bankAccount, bik, publicToken }) {
      const { data, token } = await fetch('/api/v1/tokenize', {
        body: JSON.stringify({
          type: 'bank_account_ru',
          bank_account_ru: {
            bik,
            account: bankAccount,
          },
        }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        isStrict: true,
        method: 'post',
      });

      return { data, token };
    },

    /**
     * Запрос на геренацию группы токенов для элементов.
     *
     * @param {Object} data — Данные.
     * @param {Array.<{ ref: string, type: 'card_number|cardholder|cvv|expire_date' }>} data.cardElements — Данные карты.
     * @param {string} data.publicToken — Публичный токен.
     * @returns {Promise} — Статус операции.
     */
    generate({ cardElements, publicToken }) {
      return fetch('/cds/v1/tokenize/elements', {
        body: JSON.stringify({ card_elements: cardElements }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        isAbortable: true,
        method: 'post',
      });
    },
  },

  selfEmployed: {
    init(publicToken) {
      return fetch('/widget/v1/init', {
        body: JSON.stringify({ widget_name: 'self_employed_widget' }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        method: 'post',
      }).then((response) => {
        const { error: { code } = {} } = response;

        if (code !== undefined || response instanceof Error) {
          throw new WidgetError('Что-то пошло не так', { code });
        }

        return response;
      });
    },

    requestWithStatus(url, { publicToken, ...options } = {}) {
      const headers = {
        'X-PUBLIC-TOKEN': publicToken,
      };
      const getRequestStatus = (requestId) =>
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(
              fetch('/partner/v1/npd/request/status', {
                body: JSON.stringify({ request_id: requestId }),
                headers,
                method: 'post',
              }).then((response) => {
                const { status } = response;

                switch (status) {
                  case 'ok':
                    return response;

                  case 'error':
                    return Promise.reject(response);

                  case 'pending':
                  case 'in_progress':
                  default:
                    return getRequestStatus(requestId);
                }
              }),
            );
          }, 2000),
        );

      return fetch(url, {
        ...options,
        headers,
        method: 'post',
      })
        .then((response) => {
          const { status } = response;

          if (status === 'error') {
            return Promise.reject(response);
          }

          return response;
        })
        .then(({ request_id: requestId }) => getRequestStatus(requestId))
        .catch((response) => {
          const { error: { code } = {} } = response;

          if (code !== undefined || response instanceof Error) {
            throw new WidgetError('Что-то пошло не так', { code });
          }

          return response;
        });
    },

    bind({ formToken, publicToken, taxReferenceOrPhone }) {
      const LENGTH_OF_PHONE_NUMBER = 11;
      const LENGTH_OF_TAX_REFERENCE = 12;

      const request = (payload) =>
        this.requestWithStatus('/partner/v1/npd/bind', {
          body: JSON.stringify(payload),
          formToken,
          publicToken,
        });

      if (taxReferenceOrPhone.length === LENGTH_OF_PHONE_NUMBER) {
        return request({
          phone: taxReferenceOrPhone.replace(/^8/, '7'),
        });
      }

      if (taxReferenceOrPhone.length === LENGTH_OF_TAX_REFERENCE) {
        return request({ tax_reference: taxReferenceOrPhone });
      }

      return Promise.reject();
    },

    getBindStatus({ formToken, id, publicToken, requestId }) {
      return this.requestWithStatus('/partner/v1/npd/bind/status', {
        body: JSON.stringify({
          id,
          request_id: requestId,
        }),

        formToken,
        publicToken,
      });
    },
  },

  widget: {
    /**
     * Инициализация виджета.
     *
     * @param {PublicToken} publicToken - Публичный токен.
     * @param {'acquiring_widget'|'self_employed_widget'} type - Тип виджета.
     * @returns {Promise<Object.<{ extra_data: Object }>>} - HTTP-запрос к API.
     */
    async initialization(publicToken, type) {
      const { extra_data: extraData } = await fetch('/widget/v1/init', {
        body: JSON.stringify({ widget_name: type }),
        headers: { 'X-PUBLIC-TOKEN': publicToken },
        isStrict: true,
        method: 'post',
      });

      return extraData;
    },

    /**
     * Повторная инициализация виджета.
     *
     * @param {string} hash - Хэш от ID сессии.
     * @param {string} signature - Подпись.
     * @returns {Promise<Object.<{ extra_data: Object }>>} - HTTP-запрос к API.
     */
    async reinitialization(hash, signature) {
      const { extra_data: extraData } = await fetch('/widget/v1/reinit', {
        body: JSON.stringify({ hash, signature }),
        isStrict: true,
        method: 'post',
      });

      return extraData;
    },
  },
};

export default api;
