import classNames from 'classnames';
import { h } from 'preact';
import { useState } from 'preact/compat';

import generateId from '~/utils/generateId';

import * as styles from './styles.css';

export const Field = ({
  component: Component,
  error,
  id: passedId,
  label,
  note,
  ...props
}) => {
  const isInvalid = Boolean(error);
  const [id] = useState(() => passedId ?? generateId());

  return (
    <div
      className={classNames(
        styles.container,
        isInvalid ? styles.isInvalid : null,
      )}
    >
      {label ? (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      ) : null}

      <Component {...props} id={id} isInvalid={isInvalid} />

      {isInvalid ? (
        <div className={styles.error}>{error}</div>
      ) : note ? (
        <small className={styles.note}>{note}</small>
      ) : null}
    </div>
  );
};
