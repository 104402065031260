import classNames from 'classnames';
import { h } from 'preact';

import * as styles from './styles.css';

export const Displays = {
  block: 'Block',
  inline: 'Inline',
};

export const Types = {
  button: 'button',
  reset: 'reset',
  submit: 'submit',
};

export const Widths = {
  auto: 'Auto',
  full: 'Full',
};

export const Button = ({
  buttonRef,
  children,
  display = Displays.block,
  width = Widths.full,
  type = Types.button,
  ...props
}) => (
  <button
    {...props}
    className={classNames(
      styles.container,
      styles[`display${display}`],
      styles[`width${width}`],
    )}
    ref={buttonRef}
    type={type}
  >
    {children}
  </button>
);
