import { useState } from 'preact/hooks';

import api from '~/api';
import useDidMount from '~/hooks/useDidMount';

const TYPES = {
  acquiring: 'acquiring_widget',
  selfEmployed: 'self_employed_widget',
  tokenize: 'tokenize_widget',
};

const WidgetBase = ({ hashId, publicToken, render, sign, type, ...props }) => {
  const [extraData, setExtraData] = useState(null);
  const [error, setError] = useState(null);

  useDidMount(async () => {
    try {
      if (publicToken) {
        const data = await api.widget.initialization(publicToken, type);

        setExtraData(data);
      } else if (hashId) {
        const data = await api.widget.reinitialization(hashId, sign);

        setExtraData(data);
      } else {
        setError('');
      }
    } catch (currentError) {
      setError(currentError);
    }
  });

  if (error) {
    return 'Что-то пошло не так...';
  }

  if (!extraData) {
    return null;
  }

  return render({
    extraData,
    ...props,
  });
};

WidgetBase.defaultProps = {
  hashId: null,
  publicToken: null,
  sign: null,
  type: null,
};

export { TYPES };

export default WidgetBase;
