import { h } from 'preact';

import api from '~/api';
import { InputCardNumber } from '~/components/InputCardNumber/input';
import { isCardNumber, isRequired } from '~/utils/validate';

import createTokenizer from './createTokenizer';

const CARD_ELEMENT_TYPE = 'card_number';

const minDigitsCardNumberAccepts =
  process.env.WIDGET_PAYMENT_FORM_CARD_NUMBER_ACCEPTS_14_DIGITS === 'yes'
    ? 14
    : 16;

const { Consumer, path, Provider } = createTokenizer({
  provider: {
    createToken: ({ name, publicToken, value }) =>
      api.token.create({
        name,
        publicToken,
        type: CARD_ELEMENT_TYPE,
        value,
      }),
    normalizeValue: (value) => value.replace(/\D/g, ''),
    render(props) {
      return <InputCardNumber {...props} withDeco={false} />;
    },
    validationRules: [
      isRequired(),
      isCardNumber({ minDigitsCardNumberAccepts }),
    ],
  },
  slug: CARD_ELEMENT_TYPE,
});

export { Consumer as default, path, Provider };
