import { h, createContext } from 'preact';
import { memo } from 'preact/compat';
import { useContext, useMemo } from 'preact/hooks';

const initialValue = {
  elements: {},
  publicToken: null,

  addElement(id, tokenize) {
    this.elements[id] = tokenize;
  },
  removeElement(id) {
    delete this.elements[id];
  },
};

initialValue.addElement = initialValue.addElement.bind(initialValue);
initialValue.removeElement = initialValue.removeElement.bind(initialValue);

export const TokenizerContext = createContext(initialValue);

export function useTokenizer() {
  const { elements } = useContext(TokenizerContext);
  const tokenize = (name) => {
    const tokenizeElement = elements[name];

    if (tokenizeElement === undefined) {
      return Promise.reject(`Unknown tokenizer with name "${name}".`);
    }

    return tokenizeElement();
  };
  const tokenizeAll = async (names) => {
    const tokensData = await Promise.allSettled(
      names.map((name) => tokenize(name)),
    );

    return tokensData.map(({ reason: error, status, value }, index) => {
      const name = names[index];

      if (status === 'rejected') {
        return {
          error,
          name,
          status: 'error',
        };
      }

      return {
        ...value,
        name,
        status: 'success',
      };
    });
  };

  return {
    tokenize,
    tokenizeAll,
  };
}

const Tokenizer = ({ children, publicToken, styles }) => {
  const value = useMemo(
    () => ({
      ...initialValue,
      publicToken,
      styles,
    }),
    [publicToken, styles],
  );

  return (
    <TokenizerContext.Provider value={value}>
      {children}
    </TokenizerContext.Provider>
  );
};

export default memo(Tokenizer);
