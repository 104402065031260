import checkByLuhn from '~/utils/checkByLuhn';

export const isCardNumber =
  ({ message = 'INVALID_CARD_NUMBER', minDigitsCardNumberAccepts = 16 } = {}) =>
  (value = '') => {
    if (
      value.length < minDigitsCardNumberAccepts ||
      value.length > 19 ||
      !checkByLuhn(value)
    ) {
      return message;
    }

    return undefined;
  };
