import {
  createLoader,
  useTranslationsLoader,
} from '~/hooks/useTranslationsLoader';

export const useTranslations = (locale) => {
  return useTranslationsLoader(locale, {
    en: createLoader(() => import('./en')),
    ru: createLoader(() => import('./ru')),
  });
};
