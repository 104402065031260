import classNames from 'classnames';
import { h } from 'preact';
import { forwardRef, memo } from 'preact/compat';

import { InputBase } from '~/components/InputBase';

import * as styles from './styles.css';

const Frame = forwardRef(
  ({ isFocused, isInvalid, title, url, ...props }, ref) => (
    <InputBase
      isFocused={isFocused}
      isInvalid={isInvalid}
      render={({ className }) => (
        <iframe
          {...props}
          className={classNames(className, styles.frame)}
          ref={ref}
          src={url}
          title={title}
        />
      )}
    />
  ),
);

Frame.defaultProps = {
  isFocused: false,
  isInvalid: false,
  title: 'Security container for private customer data',
};
Frame.displayName = 'Frame';

export default memo(Frame);
