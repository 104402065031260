const { parent, top } = window;
const DEFAULT_OPTIONS = {
  origin: '*',
  targets: parent !== top ? [top, parent] : [top],
};

export const post = ({ meta, payload }, options = {}) => {
  const { origin, targets } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  targets.forEach((target) =>
    target.postMessage(
      {
        meta,
        payload,
      },
      origin,
    ),
  );
};

export default { post };
