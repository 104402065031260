import { h } from 'preact';
import { useMemo } from 'preact/hooks';

import { Footer } from '~/components/Footer';

import { useTranslations } from './i18n/useTranslations';
import * as styles from './styles.css';

const DEFAULT_LOCALE = process.env.DEFAULT_LANG;
const SCOPE = process.env.PREFIX.toLowerCase();

const MainContainer = (props) => {
  const { children, locale = DEFAULT_LOCALE } = props;

  const { i18n } = useTranslations(locale);

  const texts = useMemo(() => {
    if (!i18n) {
      // Переводы еще не подгрузились.
      return null;
    }

    const translations = i18n[SCOPE];

    if (!translations) {
      // Не заданы переводы для текущего SCOPE.
      // eslint-disable-next-line no-console
      console.error(
        `Не заданы переводы для "${SCOPE}" в ~/src/containers/MainContainer/i18n/${locale}.js и, возможно, для других локалей.`,
      );

      return null;
    }

    return translations;
  }, [i18n, locale]);

  if (!texts) {
    return null;
  }

  return (
    <section className={styles.container}>
      {children}

      <Footer texts={texts} />
    </section>
  );
};

export default MainContainer;
