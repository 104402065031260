import { useEffect, useMemo, useState } from 'preact/hooks';

const DEFAULT_LANG = process.env.DEFAULT_LANG;

export const useTranslationsLoader = (locale = DEFAULT_LANG, mapLoaders) => {
  const [i18n, setI18n] = useState(null);

  const lang = useMemo(() => {
    return Object.keys(mapLoaders).includes(locale) ? locale : DEFAULT_LANG;
  }, [locale, mapLoaders]);

  useEffect(() => {
    const loader = async () => {
      const nextI18n = await mapLoaders[lang]();

      setI18n(nextI18n);
    };

    loader();
  }, [lang, mapLoaders]);

  return { i18n, lang, locale };
};
