import { useEffect, useRef } from 'preact/hooks';

export default function usePreviousValue(value) {
  const prevValue = useRef();

  useEffect(() => {
    prevValue.current = value;

    return () => {
      prevValue.current = undefined;
    };
  }, [value]);

  return prevValue.current;
}
