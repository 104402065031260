import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

/**
 * @typedef AbortableRequest
 * @property {function} abort
 * @property {Promise} request
 */

/**
 * @param {string} path
 * @param {Object} [options]
 * @param {string} [options.baseUrl=API_BASE_URL]
 * @param {Object} [options.headers={}]
 * @returns {Promise|AbortableRequest}
 */
export default (path, options) => {
  const {
    baseUrl = process.env.API_BASE_URL,
    data,
    body = JSON.stringify(data),
    headers = {},
    isAbortable = false,
    isStrict = false,
    ...restOptions
  } = options;
  const request = async ({ signal } = {}) => {
    const response = await fetch(`${baseUrl}${path}`, {
      ...restOptions,
      body,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      signal,
    });
    const { status } = response;
    const payload = await response.json();

    if (isStrict && (status < 200 || status >= 300)) {
      return Promise.reject({
        data: payload,
        status,
      });
    }

    return payload;
  };

  if (isAbortable) {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
      abort: () => controller.abort(),
      request: request({ signal }),
    };
  }

  return request();
};
