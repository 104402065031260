import { h } from 'preact';

import * as styles from './styles.css';

const builtYear = new Date(process.env.BUILT_AT).getFullYear();
const SITE_BASE_URL_DEFAULT = process.env.SITE_BASE_URL;
const SITE_NAME_DEFAULT = process.env.SITE_NAME;

export const Footer = (props) => {
  const {
    texts: {
      footer: {
        siteBaseURL = SITE_BASE_URL_DEFAULT,
        siteName = SITE_NAME_DEFAULT,
      },
    } = {},
  } = props;

  return (
    <footer className={styles.container}>
      © {builtYear},
      <a
        className={styles.link}
        href={siteBaseURL}
        rel="noopener noreferrer"
        target="_blank"
      >
        {siteName}
      </a>
    </footer>
  );
};
