const EMPTY_VALUES = ['', null, undefined];

export const isRequired =
  ({ message = 'IS_REQUIRED' } = {}) =>
  (value) => {
    if (EMPTY_VALUES.includes(value)) {
      return message;
    }

    return undefined;
  };
