import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import { InputBase } from '~/components/InputBase';
import { InputMasked } from '~/components/InputMasked';

export const InputCardNumber = forwardRef((props, ref) => {
  const {
    'aria-label': ariaLabel = 'Номер карты',
    mask = 'cardNumber',
    placeholder = '0000 0000 0000 0000',
    ...restProps
  } = props;

  return (
    <InputBase
      {...restProps}
      aria-label={ariaLabel}
      autoComplete="cc-number"
      autoCorrect="off"
      inputMode="numeric"
      mask={mask}
      placeholder={placeholder}
      ref={ref}
      render={InputMasked}
      spellCheck="false"
      type="tel"
    />
  );
});
