import { Fragment, h } from 'preact';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'preact/hooks';

import useDidMount from '~/hooks/useDidMount';
import generateId from '~/utils/generateId';

import Frame from './components/Frame';
import ProviderForm from './components/ProviderForm';
import ShadowField from './components/ShadowField';
import useCreateCommunicator from './hooks/useCreateCommunicator';
import { useDecodedUrl, useEncodedUrl } from './hooks/useUrlParams';

import { TokenizerContext } from './';

const KEY_CODE_ENTER = 13;

export default function createTokenizer({ provider, slug }) {
  const path = `/tokenize/${slug}`;

  // The two side communication channel between Consumer and Provider.
  const channel = generateId();

  const Consumer = ({ id, name, ...props }) => {
    const {
      onBlur,
      onFocus,
      onInvalid,
      onValid,
      onSubmit,
      onTokenizeStart,
      onTokenizeSuccess,
      onTokenizeFail,
      onTokenizeFulfill,
    } = props;

    const { styles, publicToken, addElement } = useContext(TokenizerContext);

    // The state of tokenizer`s focus.
    const [isFocused, setFocus] = useState(false);

    // The state of tokenizer`s validation.
    const [isInvalid, setInvalid] = useState(false);

    // Communicator between Consumer and Provider.
    const frameRef = useRef();
    const getTargets = () =>
      frameRef.current && [frameRef.current.contentWindow];
    const { useReceiver, useSender } = useCreateCommunicator(
      channel,
      getTargets,
    );

    useReceiver('ready', props.onReady);
    useReceiver(
      'blur',
      useCallback(
        (error) => {
          setFocus(false);
          onBlur(error);
        },
        [onBlur],
      ),
    );
    useReceiver('change', props.onChange);
    useReceiver(
      'focus',
      useCallback(() => {
        setFocus(true);
        onFocus();
      }, [onFocus]),
    );
    useReceiver(
      'invalid',
      useCallback(
        (error) => {
          setInvalid(true);
          onInvalid(error, name);
        },
        [onInvalid, name],
      ),
    );
    useReceiver(
      'valid',
      useCallback(() => {
        setInvalid(false);
        onValid(name);
      }, [onValid, name]),
    );
    useReceiver('submit', onSubmit);
    useReceiver('tokenizeStart', onTokenizeStart);
    useReceiver('tokenizeSuccess', onTokenizeSuccess);
    useReceiver(
      'tokenizeFail',
      useCallback(
        (error) => {
          onTokenizeFail(error, name);
        },
        [onTokenizeFail, name],
      ),
    );
    useReceiver('tokenizeFulfill', onTokenizeFulfill);

    const sendTokenize = useSender('tokenize');
    const sendFocus = useSender('focus');

    const handleShadowFieldFocus = useCallback(() => sendFocus(), [sendFocus]);

    // Binding tokenize action to TokenizerContext.
    useEffect(() => {
      addElement(
        name,
        () =>
          new Promise((resolve, reject) => {
            sendTokenize();

            const handleReceiveMessage = ({ data }) => {
              const { meta = {}, payload } = data;

              if (meta.channel !== channel) {
                return;
              }

              if (meta.eventName === 'tokenizeSuccess') {
                resolve(payload);
                window.removeEventListener('message', handleReceiveMessage);
              }

              if (
                meta.eventName === 'invalid' ||
                meta.eventName === 'tokenizeFail'
              ) {
                reject(payload);
                window.removeEventListener('message', handleReceiveMessage);
              }
            };

            window.addEventListener('message', handleReceiveMessage);
          }),
      );
    }, [name, addElement, sendTokenize]);

    return (
      <Fragment>
        {id ? <ShadowField id={id} onFocus={handleShadowFieldFocus} /> : null}

        <Frame
          isFocused={isFocused}
          isInvalid={isInvalid}
          name={channel}
          ref={frameRef}
          url={useEncodedUrl(path, { ...props, styles, publicToken })}
        />
      </Fragment>
    );
  };

  Consumer.defaultProps = {
    autoFocus: false,
    id: null,

    onReady: () => null,

    onBlur: () => null,
    onChange: () => null,
    onFocus: () => null,
    onInvalid: () => null,
    onValid: () => null,

    onSubmit: () => null,

    onTokenizeFail: () => null,
    onTokenizeFulfill: () => null,
    onTokenizeStart: () => null,
    onTokenizeSuccess: () => null,
  };

  const Provider = ({ url }) => {
    const { autoFocus, placeholder, styles, publicToken, type } =
      useDecodedUrl(url);

    // Ref to the Form element.
    const formRef = useRef();

    // Ref to the Input element.
    const inputRef = useRef();

    // The two side communication channel between Consumer and Provider.
    const consumerChannel = window.name;
    const { useReceiver, useSender } = useCreateCommunicator(
      consumerChannel,
      () => [window.parent],
    );

    const sendReady = useSender('ready');

    const sendBlur = useSender('blur');
    const sendChange = useSender('change');
    const sendFocus = useSender('focus');
    const sendInvalid = useSender('invalid');
    const sendValid = useSender('valid');

    const sendSubmit = useSender('submit');

    const sendTokenizeFail = useSender('tokenizeFail');
    const sendTokenizeFulfill = useSender('tokenizeFulfill');
    const sendTokenizeStart = useSender('tokenizeStart');
    const sendTokenizeSuccess = useSender('tokenizeSuccess');

    useReceiver(
      'focus',
      useCallback(() => {
        if (typeof inputRef.current.focus === 'function') {
          inputRef.current.focus();
        }
      }, [inputRef]),
    );
    useReceiver(
      'tokenize',
      useCallback(() => {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true }),
        );
      }, [formRef]),
    );

    useDidMount(sendReady);

    const handleKeyDown = useCallback(
      (event) => {
        const { keyCode } = event;

        if (keyCode === KEY_CODE_ENTER) {
          event.preventDefault();

          sendSubmit();
        }
      },
      [sendSubmit],
    );

    const {
      createToken,
      normalizeValue = (value) => value,
      render,
      validationRules,
    } = provider;

    const handleCreateToken = async (value) => {
      sendTokenizeStart();

      try {
        const { [slug]: tokenData } = await createToken({
          name: slug,
          publicToken,
          value: normalizeValue(value),
        });

        sendTokenizeSuccess(tokenData);
      } catch (error) {
        sendTokenizeFail(error);
      } finally {
        sendTokenizeFulfill();
      }
    };

    return (
      <ProviderForm
        styles={styles}
        autoFocus={autoFocus}
        inputRef={inputRef}
        placeholder={placeholder}
        ref={formRef}
        validationRules={validationRules}
        normalizeValue={normalizeValue}
        onBlur={sendBlur}
        onChange={sendChange}
        onFocus={sendFocus}
        onInvalid={sendInvalid}
        onValid={sendValid}
        onKeyDown={handleKeyDown}
        onSubmit={handleCreateToken}
        render={render}
        type={type}
      />
    );
  };

  return { Consumer, path, Provider };
}
