export default (value) => {
  const checksum = value
    .split('')
    .reduceRight((result, symbol, index, values) => {
      const indexRight = values.length - index - 1;
      const digit = Number(symbol);

      if ((indexRight + 1) % 2 === 0) {
        const nextDigit = digit * 2;

        if (nextDigit > 9) {
          return result + nextDigit - 9;
        }

        return result + nextDigit;
      }

      return result + digit;
    }, 0);

  return checksum % 10 === 0;
};
