import { h } from 'preact';
import { useCallback, forwardRef } from 'preact/compat';
import MaskedInput from 'react-text-mask';

const choiceMask = (maskName) => {
  const masks = {
    bankAccount: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    bik: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    cvv: Array(
      process.env.WIDGET_PAYMENT_FORM_CVV_ACCEPTS_4_DIGITS === 'yes' ? 4 : 3,
    ).fill(/\d/),
    cardholder: Array(26).fill(/[A-Za-z\s]+/),
    cardNumber: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
    ],
    expiryDate: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  };

  return masks[maskName];
};

export const InputMasked = forwardRef((props, ref) => {
  const { mask, onChange, ...restProps } = props;

  const inputElement = useCallback(
    (input) => {
      if (ref && input && input.inputElement) {
        ref.current = input.inputElement;
      }
    },
    [ref],
  );

  const handleChange = useCallback(
    (event) => {
      if (mask === 'cardholder') {
        event.target.value = event.target.value.toUpperCase();
      }

      if (mask === 'expiryDate') {
        event.target.value = event.target.value.replace(
          /[\d]*([\d]{2})$/,
          '$1',
        );
      }

      onChange(event);
    },
    [mask, onChange],
  );

  return (
    <MaskedInput
      {...restProps}
      guide={false}
      mask={choiceMask(mask)}
      onChange={handleChange}
      ref={inputElement}
    />
  );
});
